<template>
  <div class="ecom-dropdownmenu pt-0 d-none d-lg-block">
    <ul class="py-3 mb-0">
      <li v-for="(nav, index) in categories" :key="index">
        <router-link v-if=" index!=6 && index!=8 && index!=9" :to="{ name: 'ProdutosPorCategoria', params: { uuid: nav.uuid } }" class="text-decoration-none blue-grey--text darken-1" @click.native="reloadPage">
          <v-avatar tile size="20" class="mr-2">
            <img v-svg-inline class="icon" :src="categoryPath" alt="">
            <v-icon>{{ nav.icon ? nav.icon : "mdi-home" }}</v-icon>
          </v-avatar>
          <p class="m-0 mb-0 flex-grow-1">
            {{ nav.designacao }} ({{ nav.produtos_count }})
          </p>
          <!-- <v-icon v-if="nav.menuData"> mdi-chevron-right </v-icon> -->
        </router-link>
      </li>
      <!-- <v-btn color="grey" text>

      </v-btn> -->
      <div style="margin-left: -2px" class="custom-btn">
        <v-btn text :to="{ name: 'portal-todas-as-categorias' }" class="text-capitalize text-decoration-none blue-grey--text darken-1">
          <v-icon large>
            mdi-chevron-right
          </v-icon>
          Ver mais...
        </v-btn>
      </div>
    </ul>
  </div>

  <!-- another-conditions  -->
  <!-- <div
          v-else-if="
            nav.menuComponent == 'MegaMenu1' &&
            nav.menuData &&
            nav.menuData.categories
          "
          class="sub-menu"
        >
          <div class="mega-menu">
            <div class="row flex-wrap mb-4">
              <div
                class="col-md-3"
                v-for="(navTwo, index) in nav.menuData.categories"
                :key="index"
              >
                <h6 class="fw-semibold mb-3">{{ navTwo.title }}</h6>
                <ul>
                  <li
                    v-for="(navThree, index) in navTwo.subCategories"
                    :key="index"
                    class=""
                  >
                    <router-link to="/search-product">{{
                      navThree.title
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <v-img src="@/assets/images/promotion/offer-5.png"></v-img>
            </div>
          </div>
        </div> -->
  <!-- </li>
  </ul>
  </div> -->
</template>
<script>
// import navigations from "@/data/navigations";
  import { LOGIN_URL } from '@/configs/api'
  import axios from 'axios'

  export default {
    data () {
      return {
        categoryPath: require('@/assets/images/icons/laptop.svg'),
        categories: [],
        menu: [
          {
            id: 1,
            name: 'Moda',
            icon: require('@/assets/images/icons/dress.svg'),
            dropdownIcon: true,

            subMenu: [
              {
                id: 1,
                name: 'Masculino',
                icon: require('@/assets/images/icons/man.svg'),
                dropdownIcon: true,
                megaMenu: [
                  {
                    title: 'Roupas',
                    subCategories: [
                      {
                        title: 'Camisolas',
                        href: '/product/search/shirt',
                        imgUrl: '/assets/images/products/categories/shirt.png',
                      },
                      {
                        title: 'T- shirt',
                        href: '/product/search/t-shirt',
                        imgUrl: '/assets/images/products/categories/t-shirt.png',
                      },
                      {
                        title: 'Pant',
                        href: '/product/search/pant',
                        imgUrl: '/assets/images/products/categories/pant.png',
                      },
                      {
                        title: 'Underwear',
                        href: '/product/search/underwear',
                        imgUrl: '/assets/images/products/categories/t-shirt.png',
                      },
                    ],
                  },
                  {
                    title: 'Accessórios',
                    subCategories: [
                      {
                        title: 'Shirt',
                        href: '/product/search/shirt',
                        imgUrl: '/assets/images/products/categories/shirt.png',
                      },
                      {
                        title: 'T-shirt Two',
                        href: '/product/search/t-shirt',
                        imgUrl: '/assets/images/products/categories/t-shirt.png',
                      },
                      {
                        title: 'Pant',
                        href: '/product/search/pant',
                        imgUrl: '/assets/images/products/categories/pant.png',
                      },
                      {
                        title: 'Underwear',
                        href: '/product/search/underwear',
                        imgUrl: '/assets/images/products/categories/t-shirt.png',
                      },
                    ],
                  },
                  {
                    title: 'Accessories',
                    subCategories: [
                      {
                        title: 'Shirt',
                        href: '/product/search/shirt',
                        imgUrl: '/assets/images/products/categories/shirt.png',
                      },
                      {
                        title: 'T-shirt Two',
                        href: '/product/search/t-shirt',
                        imgUrl: '/assets/images/products/categories/t-shirt.png',
                      },
                      {
                        title: 'Pant',
                        href: '/product/search/pant',
                        imgUrl: '/assets/images/products/categories/pant.png',
                      },
                      {
                        title: 'Underwear',
                        href: '/product/search/underwear',
                        imgUrl: '/assets/images/products/categories/t-shirt.png',
                      },
                    ],
                  },
                  {
                    title: 'Accessories',
                    subCategories: [
                      {
                        title: 'Shirt',
                        href: '/product/search/shirt',
                        imgUrl: '/assets/images/products/categories/shirt.png',
                      },
                      {
                        title: 'T-shirt Two',
                        href: '/product/search/t-shirt',
                        imgUrl: '/assets/images/products/categories/t-shirt.png',
                      },
                      {
                        title: 'Pant',
                        href: '/product/search/pant',
                        imgUrl: '/assets/images/products/categories/pant.png',
                      },
                      {
                        title: 'Underwear',
                        href: '/product/search/underwear',
                        imgUrl: '/assets/images/products/categories/t-shirt.png',
                      },
                    ],
                  },
                ],
              },
              {
                id: 2,
                name: 'Woman',
                icon: require('@/assets/images/icons/woman.svg'),
                dropdownIcon: false,
              },
            ],
          },
          {
            id: 1,
            name: 'Groccery',
            icon: require('@/assets/images/icons/dress.svg'),
            dropdownIcon: true,
            megaMenu: [
              {
                title: 'Clothes',
                subCategories: [
                  {
                    title: 'Shirt',
                    href: '/product/search/shirt',
                    imgUrl: '/assets/images/products/categories/shirt.png',
                  },
                  {
                    title: 'T- shirt',
                    href: '/product/search/t-shirt',
                    imgUrl: '/assets/images/products/categories/t-shirt.png',
                  },
                  {
                    title: 'Pant',
                    href: '/product/search/pant',
                    imgUrl: '/assets/images/products/categories/pant.png',
                  },
                  {
                    title: 'Underwear',
                    href: '/product/search/underwear',
                    imgUrl: '/assets/images/products/categories/t-shirt.png',
                  },
                ],
              },
              {
                title: 'Accessories',
                subCategories: [
                  {
                    title: 'Shirt',
                    href: '/product/search/shirt',
                    imgUrl: '/assets/images/products/categories/shirt.png',
                  },
                  {
                    title: 'T-shirt Two',
                    href: '/product/search/t-shirt',
                    imgUrl: '/assets/images/products/categories/t-shirt.png',
                  },
                  {
                    title: 'Pant',
                    href: '/product/search/pant',
                    imgUrl: '/assets/images/products/categories/pant.png',
                  },
                  {
                    title: 'Underwear',
                    href: '/product/search/underwear',
                    imgUrl: '/assets/images/products/categories/t-shirt.png',
                  },
                ],
              },
              {
                title: 'Accessories',
                subCategories: [
                  {
                    title: 'Shirt',
                    href: '/product/search/shirt',
                    imgUrl: '/assets/images/products/categories/shirt.png',
                  },
                  {
                    title: 'T-shirt Two',
                    href: '/product/search/t-shirt',
                    imgUrl: '/assets/images/products/categories/t-shirt.png',
                  },
                  {
                    title: 'Pant',
                    href: '/product/search/pant',
                    imgUrl: '/assets/images/products/categories/pant.png',
                  },
                  {
                    title: 'Underwear',
                    href: '/product/search/underwear',
                    imgUrl: '/assets/images/products/categories/t-shirt.png',
                  },
                ],
              },
              {
                title: 'Accessories',
                subCategories: [
                  {
                    title: 'Shirt',
                    href: '/product/search/shirt',
                    imgUrl: '/assets/images/products/categories/shirt.png',
                  },
                  {
                    title: 'T-shirt Two',
                    href: '/product/search/t-shirt',
                    imgUrl: '/assets/images/products/categories/t-shirt.png',
                  },
                  {
                    title: 'Pant',
                    href: '/product/search/pant',
                    imgUrl: '/assets/images/products/categories/pant.png',
                  },
                  {
                    title: 'Underwear',
                    href: '/product/search/underwear',
                    imgUrl: '/assets/images/products/categories/t-shirt.png',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    created () {
      this.getAllCategories()
    },
    methods: {
      reloadPage () {
        setTimeout(() => {
          window.location.reload()
        }, 200)
      },
      getAllCategories () {
        axios
          .get(LOGIN_URL + '/listarCategorias')
          .then((response) => {
            this.categories = response.data
          // alert(JSON.stringify(this.categories))
          })
          .catch((error) => {})
      },
    },
  }
</script>
<style>
.custom-btn:hover {
  background-color: #89f0b7; /* Altere a cor conforme desejado */
}
</style>
<style lang="scss" scoped>
$primary-500: #42d785;
$primary-100: #e9fcf1;
$box-shadow: 0px 4px 16px rgb(43 52 69 / 10%);
$secondary: #2b3445;
$gray-900: #2b3445;

// ecomdropdown
.ecom-dropdownmenu {
  padding-top: 10px;

  &.ecom-dropdownmenu-fixed {
    display: none;
    &.open {
      display: block;
    }
  }
  ul {
    background-color: rgb(255, 255, 255);

    padding: 0;
    width: 250px;
    max-width: 250px;
    box-shadow: $box-shadow;
    border: none;
    border-radius: 0.5rem;
    &.disable {
      li {
        // background-color: $gray-100;
        a {
          font-weight: bold;
        }
      }
    }
    li {
      position: relative;
      list-style-type: none;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.594rem 1.25rem;
        text-decoration: none;
        color: $gray-900;
      }
      &:hover > {
        a {
          background-color: $primary-100;
          color: $primary-500;
          i {
            color: $primary-500;
          }
        }
        .sub-menu {
          display: block;
        }
      }
      // child ul
      .sub-menu {
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        padding-left: 1rem;
        display: none;
        z-index: 10;
        ul {
          background-color: white;
          padding: 0;
          border-radius: 0.5rem;
          box-shadow: $box-shadow;
          width: 100%;
          li {
            list-style-type: none;
          }
        }

        .mega-menu {
          min-width: 600px;
          box-shadow: $box-shadow;
          padding: 1.5625rem 1.25rem;
          border-radius: 0.5rem;
          background-color: white;
          ul {
            border: none;
            box-shadow: none;
            li {
              margin-bottom: 0.625rem;
              a {
                padding: 0;
                margin: 0;
              }
              &:hover {
                a {
                  background-color: white;
                  color: $primary-500;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
